import React from 'react';
import '../App.css';

const Footer: React.FC = () => {
  return (
    <footer className="py-4 shadow-md custom-shadow non-print-section">
    <div className="container mx-auto flex justify-between items-center px-3">
      <p className="text-center">© 2023 Copyright: Fulwon Hotel</p>
      <p className="text-right text-xs">Designed by <a href="http://chenshawn.com" target="_blank" rel="noopener noreferrer">Shawn Chen</a></p>
    </div>
  </footer>
  );
};

export default Footer;
