import { BrowserRouter, Route, Routes } from "react-router-dom";
import JwtProtectedRoute from "./pages/JwtProtectedRoute";
import RoomOrderView from "./pages/RoomOrderView";
import RoomOrderInfoView from "./pages/RoomOrderInfoView";
import GuestErrorView from "./pages/GuestErrorView";
import Footer from "./components/Footer";

import './App.css';


function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <BrowserRouter>
      <div className="flex-grow">
        <Routes>
          <Route path="/room-order-info/:roomId" element={<JwtProtectedRoute authenticationPath={'/guest-error'} outlet={<RoomOrderInfoView />} />} />
          <Route path="/order/:roomId" element={<JwtProtectedRoute authenticationPath={'/guest-error'} outlet={<RoomOrderView />} />} />
          <Route path="/guest-error" element={<GuestErrorView message={"很抱歉，好像有什麼問題。"}/>} />
        </Routes>
        </div>
        </BrowserRouter>
        <Footer />     
      </div>
  );
}

export default App;
