import { useState } from "react";
import { Navigate, useSearchParams, useParams } from "react-router-dom";
import { ProtectedRouteProps } from "../interfaces";
import Loading from "../components/Loading";
import axiosInstance from "../Axios/axios";

export default function JwtProtectedRoute({ authenticationPath, outlet}: ProtectedRouteProps) {
    const [ searchParams ] = useSearchParams();
    const { roomId } = useParams();
    const token = searchParams.get("token")
    const [authentication, setAuthentication] = useState(false as boolean);
    const [loading, setLoading] = useState(true as boolean);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    axiosInstance.get(`/room/${roomId}/activate-status`).then((data :any) => {
        if(data.data === false){
            setAuthentication(false)
            setLoading(false)
        }
    })

    axiosInstance.get('/auth/jwt-authenticate').then((data :any) => {
        if(data.data.role == "admin"){
            setAuthentication(true)
            setLoading(false)
        }else if(data.data.role == "room"){
            if(data.data.name == roomId){
                setAuthentication(true)
                setLoading(false)
            }else{
                setAuthentication(false)
                setLoading(false)
            }
        }
    }).catch((err :any) => {
        setAuthentication(false)
        setLoading(false)
    })

    if (loading) {
        return <Loading />;
      } else if (authentication) {
        return outlet;
      } else {
        return <Navigate to={{ pathname: authenticationPath }} />;
      }
  };