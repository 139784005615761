import axios from 'axios';

const baseURL = 'https://guest.fulwon-group.com/api/';

const axiosInstance = axios.create({
    // withCredentials: true,
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});



export default axiosInstance;