import { useState, useContext } from "react";
import { OrderContext } from "../context/OrderContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Quota } from "../interfaces";

function MenuItemCard(props: any) {
  const { orderRequest, setOrderRequest, quota, setQuota } = useContext(OrderContext);
  const [count, setCount] = useState(orderRequest?.orderDetail?.find((orderItem: any) => orderItem.menuItemId === props.menuItem._id)?.quantity || 0);
  const type: keyof Quota = props.menuItem.type;
  const handleMinus = () => {
    const index = orderRequest.orderDetail.findIndex((orderItemDetail) => orderItemDetail.menuItemId === props.menuItem._id)
    if(index !== -1){ 
      if(quota) setQuota!({...quota, [type]: quota[type] + 1})
      if(orderRequest.orderDetail[index].quantity === 1){
        setCount(0)
        setOrderRequest({
          ...orderRequest,
          orderDetail: orderRequest.orderDetail.filter((orderItemDetail) => orderItemDetail.menuItemId !== props.menuItem._id)
        })
      }
      else{
        setCount(count - 1)
        setOrderRequest({
          ...orderRequest,
          orderDetail: orderRequest.orderDetail.map((orderItemDetail) => {
            if (orderItemDetail.menuItemId === props.menuItem._id) {
              return {
                ...orderItemDetail,
                quantity: orderItemDetail.quantity > 0 ? orderItemDetail.quantity - 1 : 0,
              };
            } else {
              return orderItemDetail;
            }
          })
      })
    }}}

const handlePlus = () => {
  if(quota && quota[type as keyof Quota] > 0){
    setQuota!({...quota, [type]: quota[type] - 1})
    setCount(count + 1)
    if (orderRequest.orderDetail.findIndex((orderItemDetail) => orderItemDetail.menuItemId === props.menuItem._id) === -1) {
      setOrderRequest({
        ...orderRequest,
        orderDetail: [
          ...orderRequest.orderDetail,
          {
            menuItemId: props.menuItem._id,
            quantity: 1,
            name: props.menuItem.name,
            englishName: props.menuItem.englishName,
            japaneseName: props.menuItem.japaneseName,
          },
        ],
      });
    } else {
      setOrderRequest({
        ...orderRequest,
        orderDetail: orderRequest.orderDetail.map((orderItemDetail) => {
          if (orderItemDetail.menuItemId === props.menuItem._id) {
            return {
              ...orderItemDetail,
              quantity: orderItemDetail.quantity + 1,
              name: props.menuItem.name,
              englishName: props.menuItem.englishName,
              japaneseName: props.menuItem.japaneseName,
            };
          } else {
            return orderItemDetail;
          }
        }
        )}
      )}
  }else if(!quota){
    setCount(count + 1)
    if (orderRequest.orderDetail.findIndex((orderItemDetail) => orderItemDetail.menuItemId === props.menuItem._id) === -1) {
      setOrderRequest({
        ...orderRequest,
        orderDetail: [
          ...orderRequest.orderDetail,
          {
            menuItemId: props.menuItem._id,
            quantity: 1,
            name: props.menuItem.name,
            englishName: props.menuItem.englishName,
            japaneseName: props.menuItem.japaneseName,
          },
        ],
      });
    } else {
      setOrderRequest({
        ...orderRequest,
        orderDetail: orderRequest.orderDetail.map((orderItemDetail) => {
          if (orderItemDetail.menuItemId === props.menuItem._id) {
            return {
              ...orderItemDetail,
              quantity: orderItemDetail.quantity + 1,
              name: props.menuItem.name,
            };
          } else {
            return orderItemDetail;
          }
        }
        )}
      )}
  }}

return (
  <div className="h-60 my-5 flex border-b-2 p-3 mt-5">
  <div className="flex flex-col w-full">
    <div className="flex w-full h-4/5">
      <div className="w-2/3">
        <p className="text-3xl">
        {props.language === 'mandarin' && props.menuItem.name}
        {props.language === 'english' && props.menuItem.englishName}
        {props.language === 'japanese' && props.menuItem.japaneseName}
        </p>
        <p className="text-2xl">
          {props.menuItem.description} 
        </p>
      </div>
      <div className="w-1/3">
      <img src={props.menuItem.photoUrl ? props.menuItem.photoUrl : "https://res.cloudinary.com/dtziypksf/image/upload/v1685063616/no_image_muovmw.webp"} alt="" className="w-full h-full object-cover"/>
      </div>
    </div>
    <IconContext.Provider value={{ size: '2em' }}>
      <div className="flex w-full h-1/5 justify-end mt-2 rounded-md">
        <div className="w-1/3 flex bg-gray-200">
          <div className="w-1/3 flex justify-center items-center transition-all duration-300 ease-in-out hover:scale-110"
            onClick={handleMinus}
          >
            <AiOutlineMinus/>
          </div>
          <div className={`w-1/3 flex justify-center items-center text-2xl ${count == 0 ? `bg-gray-100` : `bg-green-200`}`}>
            {count}
          </div>
          <div className="w-1/3 flex justify-center items-center transition-all duration-300 ease-in-out hover:scale-110"
          onClick={handlePlus}
          >
            <AiOutlinePlus/>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  </div>
</div>
  );
}

export default MenuItemCard;
