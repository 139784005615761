import { createContext } from "react";
import { OrderRequest, Quota } from "../interfaces";


type OrderContextType = {
    orderRequest: OrderRequest;
    setOrderRequest: (orderRequest: OrderRequest) => void;
    quota?: Quota;
    setQuota?: ((quota: Quota) => void);
}

export const OrderContext = createContext<OrderContextType>({} as OrderContextType);


